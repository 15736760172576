import {format, startOfMonth, endOfMonth } from 'date-fns';

const LoadWeekendUtils = () => {
  let listDisabled = []
  const year =format(new Date(Date.now()), 'yyyy');
  const month = format(new Date(Date.now()), 'MM');
  const start = startOfMonth(new Date(Date.now()))
  const end = endOfMonth(new Date(Date.now()))
  
  for (let i = start.getDate(); i <= end.getDate(); i++) {
    const date = `${year}-${month}-${i} 00:00:00`
    const newDate = new Date(date)
    if (newDate.getDay() === 6 || newDate.getDay() === 0) {
      listDisabled.push(newDate);
    }
  }
  return listDisabled;
}


export default LoadWeekendUtils;