import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  outline:0;
  box-sizing: border-box;
}

body{
  background-color: #eee;
  color: #222;
}
input,
button,
textarea{
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
}
`;

export default GlobalStyles