import httpApiSiga from "../httpApiSiga"

const HoursAllowService = (employerId: string, date: string) => {
  return httpApiSiga.get(`/calendar/meeting/hours/allow?id=${employerId}&date=${date}`)
    .then(response => {
      return response.data.data
    }).catch(err => {
      console.error(err);
    })
}

export default HoursAllowService