import httpApiSiga from "../httpApiSiga"

const MeetingsList = async (id: string) => {
  return await httpApiSiga.get(`/calendar/meeting/employer/${id}`)
  .then(response => {
    return response.data.data;
  }).catch(err => {
    console.log(err);
  })
}
export default MeetingsList;