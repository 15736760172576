import { useNavigate } from "react-router-dom"
import { Button, Col, Container } from "reactstrap"
import { CardSelect } from "../../styles/Select"

const Index = () => {
  const navigate = useNavigate();
  const budgetId = 123;

  return (
    <Container>
      <CardSelect xs={6} className="bg-light p-5">

        <Col xs={12} className="d-flex justify-content-center mb-5">
          <img
            src={"https://www.studytravelworkips.com/logo.png"}
            alt="Logo"
            width={80}
          />
        </Col>

        <p>Foi feita a assinatura do contrato com sucesso. Agora e preciso fazer uma reunião com o pessoal do nosso time.
          Clique no botão continuar para poder agendar uma reunião
        </p>
        <Button color="primary" onClick={() => navigate(`/select/${budgetId}`)}>
          Continuar
        </Button>

      </CardSelect>
    </Container>
  )
}

export default Index;