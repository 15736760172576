import { CSSProperties } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import { Col } from "reactstrap";


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


const LoadingComponent: React.FC = () => {
  return (
    <Col className='d-flex justify-content-center align-items-center flex-column m-5'>
      <CircleLoader color={"#2980b9"} cssOverride={override} size={80} />
      <strong className='mt-2'> Carregando...</strong>
    </Col>
  )
}
export default LoadingComponent