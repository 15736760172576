import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Calendar from './pages/Calendar';
import Index from './pages/Index';
import Login from './pages/Login';
import Select from './pages/Select';
import Success from './pages/Success';
import GlobalStyles from './styles/GlobalStyles';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index/>} />
          <Route path="/select/:budgetId" element={<Select />} />
          <Route path="/contract/:budgetId/:userId" element={<Calendar/>} />
          <Route path="/success" element={<Success/>} />
          <Route path="/login" element={<Login/>} />
        </Routes>
      </BrowserRouter>
      <GlobalStyles />

    </>
  )
}
export default App;