
import httpApiSiga from "../httpApiSiga"

const ShowEmployerDetails = (id: string) => {
  return httpApiSiga.get(`/calendar/employer/${id}`)
  .then(response => {
    return response.data.data;
  })
  .catch(err => {
    console.error(err);
  })
}

export default ShowEmployerDetails;