import React, { useEffect, useState } from 'react';
import { Button, Input, Label, FormGroup, Col } from 'reactstrap'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script';
import httpApiSite from '../../services/httpApiSite';
function Login() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: "62778983348-rjsiflgod7q8g5canthjrb6schdp1qng.apps.googleusercontent.com",
        scope: "openid email profile https://www.googleapis.com/auth/calendar"
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const responseGoogle = (response: any) => {
    httpApiSite.post('/calendar/create-tokens', {
      code: response
    })
      .then(response => {
        console.log(response.data);
        setSignedIn(true);
      })
      .catch(err => {
        console.log(err)
        alert("Houve um error ao pegar o token.");
      })
  }
  const responseError = (error: any) => {
    console.error(error)
  }

  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [signedIn, setSignedIn] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    httpApiSite.post("/calendar/create-event", {
      summary,
      description,
      location,
      startDateTime,
      endDateTime
    })
      .then(response => {
        console.log(response.data);
      })
      .catch(err => {
        console.log("Houve um erro.");
        console.log(err.message)
      });


  }


  return (
    <>
      <div className="App">
        <h1>Google Calendar API</h1>
        {
          !signedIn ? (
            <div>
              <GoogleLogin
                clientId="62778983348-rjsiflgod7q8g5canthjrb6schdp1qng.apps.googleusercontent.com"
                buttonText="Sign in & Authorize Calender"
                onSuccess={responseGoogle}
                onFailure={responseError}
                cookiePolicy={"single_host_origin"}
                //This is important
                responseType="code"
                accessType="offline"
                scope="openid email profile https://www.googleapis.com/auth/calendar"
              />
            </div>
          ) : (
            <div>
              <form onSubmit={handleSubmit}>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="summary">Summary</Label><br />
                    <Input type="text" id="summary" value={summary} onChange={(e: any) => setSummary(e.target.value)} />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="description">Description</Label><br />
                    <Input type="textarea" id="description" value={description} onChange={(e: any) => setDescription(e.target.value)}></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="location">Location</Label><br />
                    <Input type="text" id="location" value={location} onChange={(e: any) => setLocation(e.target.value)} /> <br />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="startDateTime">Start Date time</Label><br />
                    <Input type="datetime-local" id="startDateTime" value={startDateTime} onChange={(e: any) => setStartDateTime(e.target.value)} /> <br />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="endDateTime">End Date time</Label><br />
                    <Input type="datetime-local" id="endDateTime" value={endDateTime} onChange={(e: any) => setEndDateTime(e.target.value)} /> <br />
                  </FormGroup>
                  <Button color="primary" type='submit'>Create event</Button>

                </Col>
              </form>
            </div>
          )
        }



      </div>
    </>
  );
}

export default Login;
