import { useEffect, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label } from "reactstrap";
import Calendary from "react-calendar";
import Swal from 'sweetalert2';
import { useNavigate, useParams } from "react-router-dom";
import withReactContent from 'sweetalert2-react-content';
import { format, add } from 'date-fns'

import { CardCalendar } from "../../styles/Calendar";
import ITileDisabled from "../../dtos/ITileDisabled";
import "../../styles/css/calendar.css";
import IListEmployer from "../../dtos/IListEmployer";
import LoadingComponent from "../../components/LoadingComponent";
import ShowEmployerDetails from "../../services/siga/showEmployerDatails";
import MeetingsList from "../../services/siga/meetingsList";
import LoadWeekendUtils from "../../utils/LoadWeeksUtils";
import HoursAllowService from "../../services/siga/hoursAllowService";
import saveMeetService from "../../services/siga/saveMeetService";

const MySwal = withReactContent(Swal)



const Calendar: React.FC = () => {

  const navigate = useNavigate();
  const params = useParams();
  const { budgetId, userId } = params;

  const [value, onChange] = useState(new Date());
  const [selectDay, setSelectDay] = useState(false);
  const [listHours, setListHours] = useState([]);
  const [hourSelect, setHourSelect] = useState<null | string>(null);
  const [employerData, setEmployerData] = useState<null | IListEmployer>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [disabledDates, setDisabledDates] = useState<null | any>(null)


  useEffect(() => {

    const loadMeetings = async () => {

      const employer = localStorage.getItem("employerId") as string;
      const detailsEmployer = await ShowEmployerDetails(employer);

      const meetingsDateEmployer = await MeetingsList(detailsEmployer.id);
      setEmployerData(detailsEmployer);

      const listDateDisabled = [] as any;
      const listWeeks = LoadWeekendUtils();
      listWeeks.map((week: any) => listDateDisabled.push(week))
      meetingsDateEmployer.map((date: any) =>
        date._count >= detailsEmployer.meetPerDay &&
        listDateDisabled.push(new Date(`${date.day_name} 00:00:00`)));

      setDisabledDates(listDateDisabled)
      setLoading(false);
    }
    loadMeetings();
  }, []);

  const handleHour = (hour: string) => {
    setHourSelect(hour)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    
    if (employerData && userId && budgetId && hourSelect) {
      const start = new Date(`${format(value, 'yyyy-MM-dd')} ${hourSelect}`)
      const end = add(new Date(start), { minutes: parseInt(employerData.timeMeet) });
      const data = {
        employerId: userId,
        hour: hourSelect,
        day_number: parseInt("05"),
        day_name: format(new Date(value), "yyyy-MM-dd"),
        date_start: start,
        date_end: end,
        customer_name: name,
        customer_email: email,
        customer_phone: phone,
        budgetId: budgetId

      }
      await saveMeetService(data)
      .then(response => {
        console.log(response);
      });
      navigate(`/success?name=${employerData.user.name}&date=${value}&hour=${hourSelect}`);
    }else{
      MySwal.fire({
        icon: "error",
        title: "Houve um erro",
        text: "Não foi possível cadastrar a reunião, tente mais tarde."
      })
    }
  }
  const handleDate = async (e: any) => {
    onChange(e);
    if (value) {
      if (employerData) {
        const listHours = await HoursAllowService(employerData.id, format(e, "yyyy-MM-dd"));
        console.log(listHours);

        setListHours(listHours);
        setSelectDay(true);
      } else {
        MySwal.fire({
          icon: "error",
          title: "Houve um erro",
          text: "Não foi possível listar os horários desse colaborador."
        })
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Houve um erro",
        text: "Não foi possível listar os horários desse colaborador."
      })
    }
  };
  const handleDisabledDate = () => {
    setSelectDay(false);
    setListHours([]);
  };

  return (
    <Container>
      <CardCalendar xs={11} sm={8} md={6} className="bg-light p-5">
        <Col xs={12} className="d-flex justify-content-center mb-5">
          <img
            src={"https://www.studytravelworkips.com/logo.png"}
            alt="Logo"
            width={80}
          />
        </Col>
        {loading === true && (
          <LoadingComponent />
        )}
        {loading === false && (
          <>
            {employerData && (
              <Col>
                <strong>Sua reunião será com: {employerData.user.name}</strong>
              </Col>
            )}
            {selectDay === false && (
              <>
                <h5>Selecione uma data</h5>
                <Calendary
                  onChange={(e: any) => handleDate(e)}
                  value={value}
                  activeStartDate={new Date(Date.now())}
                  tileClassName="tile"
                  tileDisabled={({ date, view }: ITileDisabled) =>
                    view === "month" && // Block day tiles only
                    !!disabledDates && disabledDates.some((dates: any) =>
                      date.getFullYear() === dates.getFullYear() &&
                      date.getMonth() === dates.getMonth() &&
                      date.getDate() === dates.getDate()
                    )
                  }
                />
              </>
            )}

            {selectDay === true &&
              hourSelect === null &&
              listHours !== null && (
                <>
                  <Button className="mb-3" color="danger" onClick={handleDisabledDate}>
                    Voltar
                  </Button>
                  <h5>Selecione um horário diponível</h5>
                  {listHours.map((hour: any) => (
                    <Button
                      key={hour.hour}
                      color="primary"
                      className="m-1"
                      onClick={() => handleHour(hour.hour)}
                      disabled={hour.disabled}
                      outline
                    >{hour.hour}</Button>
                  ))}
                </>
              )}

            {hourSelect !== null && (
              <>
                <h5 className="mb-3">Seus dados</h5>
                <form onSubmit={handleSubmit}>

                  <FormGroup>
                    <Label>Nome</Label>
                    <Input placeholder="seumelhor@email.com" type="text" value={name} onChange={(e: any) => setName(e.target.value)} />
                  </FormGroup>

                  <FormGroup>
                    <Label>E-mail</Label>
                    <Input placeholder="seumelhor@email.com" type="email" value={email} onChange={(e: any) => setEmail(e.target.value)} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Telefone</Label>
                    <Input placeholder="+00 00 00000-0000" type="text" value={phone} onChange={(e: any) => setPhone(e.target.value)} />
                  </FormGroup>

                  <Button color="primary" type="submit">Salvar</Button>
                </form>
              </>
            )}
          </>
        )}
      </CardCalendar>


    </Container>
  )
}
export default Calendar;