import { useEffect, useState } from 'react';
import { Container, Input, InputGroup, Button, Col } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'


import httpApiSiga from '../../services/httpApiSiga';
import IListEmployer from '../../dtos/IListEmployer';
import { CardSelect } from '../../styles/Select';
import LoadingComponent from '../../components/LoadingComponent';


function Select() {
  const navigate = useNavigate();
  const { budgetId } = useParams();

  const [userId, setUserId] = useState<any>("DEFAULT");
  
  const [listUsers, setListUsers] = useState<null | IListEmployer[]>(null);
  const handleNext = () => {
    localStorage.setItem("employerId", userId)
    return navigate(`/contract/${budgetId}/${userId}`);
  }


  useEffect(() => {
    httpApiSiga.get("/calendar/employer")
      .then(response => {
        setInterval(() => {
          setListUsers(response.data.data);
        }, 5000)
      })
  }, [listUsers]);

  const handleUser = (e: any) => {
    e.preventDefault();
    const userID = e.target.value
    setUserId(userID)
  }

  return (
    <Container>
      <CardSelect xs={11} sm={8} md={6} className="bg-light p-5">

        <Col xs={12} className="d-flex justify-content-center mb-5">
          <img
            src={"https://www.studytravelworkips.com/logo.png"}
            alt="Logo"
            width={80}
          />
        </Col>

        <h5>
          Selecione um atendente para fazer reunião
        </h5>

        {listUsers === null && <LoadingComponent />}

        {listUsers !== null && (
          <InputGroup>
            <Input
              id="colaboradores" type="select"
              onChange={(e: any) => handleUser(e)}
              value={userId}
            >
              <option
                selected
                value={"DEFAULT"}
                disabled
              >
                Selecione uma opção
              </option>

              {listUsers.map(user => (
                <option
                  key={user.id}
                  value={user.id}
                >
                  {user.user.name}
                </option>
              ))}
            </Input>
          </InputGroup>
        )}

        {userId === "DEFAULT" ? (
          <Button color='primary' className='mt-3' disabled>Proximo</Button>
        ) : (
          <Button color='primary' className='mt-3' onClick={handleNext}>Proximo</Button>
        )}
      </CardSelect>
    </Container>
  )
}

export default Select;