import { Button, Col, Container } from "reactstrap"
import { CardCalendar } from "../../styles/Calendar"
import { FiCheckCircle } from 'react-icons/fi'
import { useSearchParams } from "react-router-dom"
import { format } from 'date-fns'
const Success: React.FC = () => {
  const [searchParams] = useSearchParams();
  const nome = searchParams.get("name");
  const date = searchParams.get("date") as string
  const dia = format(new Date(date), "dd/MM/yyyy");
  const hora = searchParams.get("hour");

  return (
    <>
      <Container>
        <CardCalendar className="d-flex flex-column justify-content-center align-items-center">
          <FiCheckCircle className="mx-auto my-3" color="#27ae60" size={70} />

          <h4 className="text-center">Tudo deu certo!</h4>

          <p>Seu agendamento foi marcado com sucesso. Sua reunião vai ser com {nome}, para o dia {dia} às {hora}</p>

          <Col xs={6}>
            <Button style={{ width: "100%" }} color="primary" outline href="https://studytravelworkips.com/">Confirmar</Button>
          </Col>

        </CardCalendar>
      </Container>
    </>
  )
}

export default Success;