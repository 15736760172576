import httpApiSiga from "../httpApiSiga";

interface IMeet {
  employerId: string;
  hour: string;
  day_number: number;
  day_name: string;
  date_start: Date;
  date_end: Date;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  budgetId: string;
}
const saveMeetService = (data: IMeet) => {
  return httpApiSiga.post("/calendar/meeting/new", data)
  .then(response => {
    return response.data
  })
  .catch(err => {
    console.error(err);
  })
}
export default saveMeetService;